/*** Body ***/

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0 auto;
  max-width: 2000px;
  font-family: 'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--fs-text);
  background-color: var(--clr-bg);
  color: var(--clr-text);
  position: relative;
}
.App-main {
  padding: 0 var(--sm-spacing);
}
svg, img {
  display: block;
}

/*** Typography ***/

h1,
.as-h1 {
  line-height: 1;
  letter-spacing: -.03em;
  font-size: clamp(2rem, 1.49rem + 3.3vw, 6rem);
  font-weight: 400;
}
h2,
.as-h2 {
  line-height: 1.2;
  font-size: clamp(1.5rem, 1.39rem + 2.5vw, 3.5rem);
  font-weight: 400;
  letter-spacing: -.03em;
}
h3 {
  line-height: 1.25;
  font-size:  clamp(1.25rem, 1.15rem + 1.07vw, 2rem);
  font-weight: 400;
}
h4 {
  font-size: clamp(1.125rem, 1.11rem + 0.71vw, 1.75rem);
}
h5, h6 {
  font-size: clamp(1.1rem, 1.09rem + 0.18vw, 1.25rem);
}
.subtitle {
  text-transform: uppercase;
  font-size: .67em;
  color: var(--clr-primary-600);
  letter-spacing: .1em;
  font-weight: 600;
  margin: 0;
}

p, ul, li, cite, blockquote, figcaption {
  line-height: 1.7;
  max-width: 50ch;
  font-weight: 350;
  letter-spacing: .03em;
}
p, li {
  color: var(--clr-text-alt);
}
.text-fade {
  color: var(--clr-text-alt);
  opacity: .6;
}
li::marker {
  font-size: .6em;
}
a {
  color: var(--clr-primary-600);
  text-decoration: none;
  transition: color 150ms ease-in;
}
a:hover {
  color: var(--clr-primary-400);
}
.bw {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
@media (min-width: 900px) {
  :root {
    --sm-spacing: 2rem;
    --inner-spacing: 0;
    --fs: 22px;
    --y-spacing: 5rem;
  }
}
@media (min-width: 1200px) {
  :root {
    --sm-spacing: 3rem;
    --inner-spacing: 3rem;
  }
}
@media (min-width: 3600px) {
  :root {
    --fs: 32px;
  }
}

/*** Grid ***/

.block-grid {
  display: grid;
  grid-template-columns: 1fr;
}
.block {
  padding: var(--y-spacing) var(--inner-spacing);
}
.block:first-child {
  padding-top: var(--sm-spacing);
}
@media (min-width: 900px) {
  .block:first-child {
    padding-top: calc(var(--y-spacing) * 2);
  }
}

/*** Flex ***/

.flex {
  display: flex;
}
.fspbw {
  justify-content: space-between;
}
.fcent {
  justify-content: center;
}
.fend {
  justify-content: flex-end;
}
.fvcent {
  align-items: center;
}
.fvend {
  align-items: flex-end;
}
.fcol {
  flex-direction: column;
}
.fwrap {
  flex-wrap: wrap;
}
.fgap-sm {
  gap: .5rem;
}

/*** BREAKPOINTS ***/

.--sm,
.--md,
.--lg,
.--xlg {
  display: none;
}
@media (min-width: 600px) {
  .--xsm {
    display: none !important;
  }
  .--sm {
    display: block;
  }
}
@media (min-width: 900px) {
  .--xmd {
    display: none !important;
  }
  .--md {
    display: block;
  }
}
@media (min-width: 1200px) {
	.--lg {
		display: block;
	}
}
@media (min-width: 1536px) {
	.--xlg {
		display: block;
	}
}