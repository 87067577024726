.hero-section-container {
    min-height: min(50vh, 660px);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    gap: var(--sm-spacing);
}
.hero-section-container ul {
    list-style-type: none;
}
.hero-section-container li {
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: .02em;
    line-height: 1.666;
}
.hero-section-content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
}
.hero-section-content ul:first-child li {
    color: var(--clr-primary-600);
}
.education-container li {
    display: grid;
    grid-template-columns: 5rem 1fr;
    gap: 1rem;
    align-items: center;
    font-size: .8rem;
    max-width: 100%;
}
.education-container li > span {
    display: inline-block;
}
.hero-section-footer ul {
    display: grid;
    grid-template-columns: repeat(4, min-content);
    gap: 1.5rem;
}
.hero-section-footer li {
    font-weight: 400;
}

.hero-section-body {
    grid-row: 2;
}
.hero-section-image {
    max-height: 100%;
    grid-row: 1;
    grid-column: 1;
    display: grid;
    place-content: center;
}
.hero-section-image img {
    max-width: 100%;
    object-fit: cover;
    border-radius: 25% 50% 25% 50%;
}

.about-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
}
@media (min-width: 600px) {
    .hero-section-footer ul {
        gap: 2rem;
    }
    .hero-section-content {
        grid-template-columns: minmax(min-content, 12rem) 1fr;
    }
}
@media (min-width: 900px) {
    .hero-section-body {
        grid-row: 1;
        grid-column: 1;
    }
    .hero-section-image {
        grid-column: 2;
    }
    .hero-section-container {
        grid-template-columns: 1fr 40%;
        grid-template-rows: 1fr;
    }
}
@media (min-width: 1536px) {
    .hero-section-container {
        grid-template-columns: 1fr 1fr;
    }
}