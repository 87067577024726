svg {
    font-size: inherit;
    color: currentColor;
}
.as-h1 svg {
    height: 3rem;
    width: 3rem;
}
.menu-icon {
    display: grid;
    grid-template-columns: 1fr;
    gap: .36rem;
    width: 2rem;
}
.menu-icon span {
    display: block;
    width: 100%;
    height: .2rem;
    position: relative;
    background-color: currentColor;
    border-radius: .2rem;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
        background-color 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
        opacity 0.55s ease;
}
.menu-icon span {
    transform-origin: center center;
}
.menu-icon.--active span {
    opacity: 1;
}
.menu-icon.--active span:first-child {
    transform: rotate(45deg) translate(.4rem, .4rem);
}
.menu-icon.--active span:nth-child(2) {
    transform: rotate(-45deg) translate(0, 0);
}
.menu-icon.--active span:nth-child(3) {
     opacity: 0;
    transform: scale(0.2, 0.2);
}