.article-wrapper {
    --max-vid-y: 450px;
    --text-margin: clamp(1.375rem, 1.2rem + 0.89vw, 2rem);
}
.article-wrapper h2 {
    max-width: 25ch;
}
.article-wrapper h3,
.article-wrapper h4 {
    max-width: 40ch;
}
.article-wrapper h2,
.article-wrapper h3,
.article-wrapper h4 {
    margin-bottom: 1rem;
}
.article-wrapper p:not(:last-child):not(.precaption),
.article-wrapper ul:not(:last-child),
.article-wrapper figure,
.article-wrapper blockquote,
.article-wrapper .as-text:not(:last-child) {
    margin-bottom: var(--text-margin);
}
.article-wrapper p.precaption {
    margin-bottom: .5rem;
}
.article-wrapper p {
    max-width: 70ch;
}
.article-wrapper cite,
.article-wrapper blockquote,
.article-wrapper li {
    max-width: 65ch;
}
.article-wrapper * + h3 {
    margin-top: clamp(2.375rem, 1.98rem + 1.96vw, 3.75rem);
}
.article-wrapper strong,
.article-wrapper cite {
    color: var(--clr-primary-600);
}
.article-wrapper blockquote {
    padding: 1em;
    font-style: italic;
    border-left: 1px solid var(--clr-primary-600);
    color: var(--clr-primary-600);
}
.article-wrapper blockquote::before,
.article-wrapper blockquote::after {
    content: "\"";
}
.article-wrapper ul {
    margin-left: 2rem;
}
.article-wrapper li {
    padding-inline-start: .5rem;
}
.article-wrapper li:not(:first-child) {
    margin-top: .5rem;
}
.article-wrapper li::marker {
    color: var(--clr-primary-600);
    content: "➤";
}
@media (min-width: 600px) {
    .article-wrapper blockquote {
        padding: 2rem 1.5rem;
    }
}
