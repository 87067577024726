/*** Root ***/

:root {
  --sm-spacing: 1.5rem;
  --inner-spacing: 0;
  --y-spacing: 2rem;

  --fs: 16px;
  --fs-text: clamp(1rem, 0.96rem + 0.18vw, 1.125rem);
}

.--dark-theme {
  --clr-secondary-0: #59b4c3;
  --clr-secondary-200: #3c97a6;
  --clr-secondary-400: #2C707B;
  --clr-secondary-600: #15616D;
  --clr-secondary-800: #104D57;

  --clr-primary-200: #ffb267;
  --clr-primary-400: #ffab00;
  --clr-primary-600: #ff6d00;
  --clr-primary-800: #c43c00;

  --clr-text: #f8f9fa;
  --clr-text-alt: #d5d7d8;
  --clr-text-light: var(--clr-primary-600);
  --clr-inverse-text: #001524;
  --clr-weak-bg: #002038;
  --clr-fade: rgba(255, 253, 250, .1);

  --clr-bg: #001524;
  --clr-xdark: #000a10;
}

.--art-theme {
  --clr-secondary-0: #000a10;
  --clr-secondary-200: #000a10;
  --clr-secondary-400: #000a10;
  --clr-secondary-600: #dfe1e1;
  --clr-secondary-800: #dfe1e1;

  --clr-primary-200: #373a3c;
  --clr-primary-400: #373a3c;
  --clr-primary-600: #000a10;
  --clr-primary-800: #000a10;

  --clr-text: #001524;
  --clr-text-alt: #000a10;
  --clr-text-light: #373a3c;
  --clr-inverse-text: #d5d7d8;
  --clr-weak-bg: #dfe1e1;
  --clr-fade: rgba(21.6, 22.7, 23.5, .05);

  --clr-bg: #f8f9fa;
  --clr-xdark: #d5d7d8;
}