.item-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    padding: 0;
    --max-vid-y: 288px;
    --max-vid-x: 512px;
    max-width: var(--max-vid-x);
    width: 100%;
    margin-inline: auto;
    --x-padding: 1rem;
}
.description-container {
    padding: 1.5rem var(--x-padding);
    border: 1px solid var(--clr-fade);
    border-top-width: 0;
    position: relative;
    isolation: isolate;
    font-size: var(--fs-text);
}
.description-container h3 {
    font-size: 1.5rem;
    margin: 0;
}
.description-container > .article-wrapper > *:last-child {
    margin-bottom: 2rem;
}
.attribution-item a {
    font-family: inherit;
    color: inherit;
    font-weight: inherit;
}
.attribution-item a:hover {
    text-decoration: underline;
}
.item-container ul {
    margin-left: 2rem;
    break-inside: avoid;
}
.item-container li:not(:first-child) {
    margin-top: .5rem;
}
.item-container li {
    padding-inline-start: .5rem;
}
.item-container li::marker {
    color: var(--clr-primary-600);
    content: "➤";
}
.video-container {
    overflow: visible;
    background: radial-gradient(at top, var(--clr-fade) 30%, transparent 70%);
}
.description-footer-action {
    min-height: 1rem;
}

.description-button > span {
    padding-left: .5rem;
}

/*Expandable*/
.--expandable.--inactive .description-container {
    max-height: var(--max-vid-y);
    overflow-y: hidden;
}
.--expandable.--inactive .expandable-overlay {
    position: absolute;
    inset: 0;
    background: linear-gradient(to bottom, transparent 40%, var(--clr-bg) 85%);
}
.--active .expandable-overlay {
    background: transparent;
}
.--inactive .description-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    padding-inline: var(--x-padding);
    width: 100%;
}
.expand-action a {
    padding: 1rem 0;
    font-size: .9rem;
}
.footer-action-label {
    font-size: .5rem;
    display: none;
}
@media (min-width: 400px) {
    .footer-action-label {
        display: inline;
    }
}
@media (min-width: 600px) {
    .footer-action-label {
        font-size: unset;
    }
    .description-container h3 {
        font-size: 2rem;
    }
}
@media (min-width: 1200px) {
    .--expandable.--active .description-container {
        border-bottom-width: 0;
    }
    .description-container {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .item-container {
        grid-template-columns: minmax(256px, 512px) 1fr;
        grid-template-rows: 1fr;
        max-width: 100%;
        --x-padding: 2rem;
    }
}
@media (min-width: 1536px) {
    .item-container {
        width: 84rem;
    }
}