button.primary {
    --bg: var(--clr-primary-600);
    --outline-focus: var(--clr-primary-600);
    --border-focus: var(--clr-light);
    --bg-hover: var(--clr-primary-400);
    --fs-clr: var(--clr-inverse-text);
    --padding: 1rem 2rem;
    position: relative;
}
button.secondary {
    --bg: transparent;
    --outline-focus: transparent;
    --border-focus: transparent;
    --bg-hover: rgba(255, 253, 250, .07);
    --fs-clr: var(--clr-text);
    --padding: 1rem 2rem;
    position: relative;
}
button.text,
button.icon {
    --bg: transparent;
    --outline-focus: transparent;
    --border-focus: transparent;
    --bg-hover: transparent;
    --fs-clr: var(--clr-text);
    --padding: 1rem;
}
button.icon svg {
    margin-inline: auto;
}
button.--selected {
    color: var(--clr-secondary-0) !important;
}

button {
    padding: var(--padding);
    font-size: .8rem;
    border: 1px solid transparent;
    background-color: var(--bg);
    color: var(--fs-clr);
    cursor: pointer;
    transition: background-color 150ms ease-in, color 150ms ease-in;
    letter-spacing: .07em;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1;
    font-family: inherit;
    overflow: hidden;
}
button:hover {
    background-color: var(--bg-hover);
}
button.text:hover {
    color: var(--clr-primary-600);
}
button:focus {
    border-color: var(--border-focus);
    outline: 2px solid var(--outline-focus);
}

/* button effects */
button .inner-text-original {
    background: transparent;
    display: grid;
    inset: 0;
    place-content: center;
    position: absolute;
    transition: transform 0.2s;
}
button:hover .inner-text-original {
    transform: translateY(100%);
}
button .inner-text-single-letters > span {
    opacity: 0;
    transform: translateY(-15px);
    transition: transform 0.2s, opacity 0.15s;
}
button:hover .inner-text-single-letters > span {
    opacity: 1;
    transform: translateY(0);
}
button:hover .inner-text-single-letters > span:nth-child(2) {
    transition-delay: 0.1s;
}
button:hover .inner-text-single-letters > span:nth-child(3) {
    transition-delay: 0.2s;
}
button:hover .inner-text-single-letters > span:nth-child(4) {
    transition-delay: 0.29s;
}
button:hover .inner-text-single-letters > span:nth-child(5) {
    transition-delay: 0.37s;
}
button:hover .inner-text-single-letters > span:nth-child(6) {
    transition-delay: 0.43s;
}
button:hover .inner-text-single-letters > span:nth-child(7) {
    transition-delay: 0.49s;
}
button:hover .inner-text-single-letters > span:nth-child(8) {
    transition-delay: 0.54s;
}
button:hover .inner-text-single-letters > span:nth-child(9) {
    transition-delay: 0.58s;
}
button:hover .inner-text-single-letters > span:nth-child(10) {
    transition-delay: 0.61s;
}
button:hover .inner-text-single-letters > span:nth-child(11) {
    transition-delay: 0.63s;
}
button span.--space {
    margin-left: .1rem;
    margin-right: .1rem;
}