.hero-container {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    gap: 2.5rem;
}
.hero-title {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2.5rem;
    grid-row: 2;
}
.hero-image {
    grid-row: 1;
    --max-vid-y: 450px;
}
.hero-image img {
    object-fit: cover;
    max-width: 100%;
}
@media (min-width: 600px) {
    .hero-container {
        min-height: min(70vh, 1000px);
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
    }
    .hero-title {
        grid-row: 1;
    }
}
@media (max-width: 600px) {
    .hero-title h1 {
        text-align: center;
    }
    .hero-title .hero-actions {
        justify-content: center;
    }
}