.App-footer {
    margin-top: calc(var(--y-spacing) * 2);
    padding: var(--sm-spacing);
    --bg-x: center;
    --bg-y: right;
}
.--dark-theme .App-footer {
    background: radial-gradient(
        at var(--bg-x) var(--bg-y),
        var(--clr-fade), transparent
    );
}
.--art-theme .App-footer {
    border-top: 1px solid var(--clr-primary-200);
}
.footer-top {
    grid-gap: 2rem;
    align-items: flex-end;
}
.footer-top,
.footer-panel-1 {
    display: grid;
    grid-template-columns: 1fr;
}
.footer-logo {
    width: 128px;
}
.footer-nav-separator {
    padding: 0 1rem;
}
.footer-panel-2 > div:not(:last-child) {
    margin-bottom: clamp(1rem, 2vw, 1.5rem);
}
.list-item-icon {
    margin-right: 1rem;
}
.list-item-icon > div {
    background-color: var(--clr-secondary-800);
    padding: .5em;
    display: grid;
    place-content: center;
    border-radius: 50%;
}
.footer-copyright-container {
    margin-top: var(--sm-spacing);
}
.footer-copyright {
    font-size: 1rem;
}
.footer-copyright-disclaimer {
    font-size: .67rem;
    color: var(--clr-primary-200);
}
@media (min-width: 600px) {
    .App-footer {
        --bg-x: bottom;
        --bg-y: left;
    }
    .footer-logo {
        margin-bottom: 1rem;
        width: 256px;
    }
    .footer-top {
        grid-template-columns: 1fr 1fr;
    }
    .footer-panel-3 {
        grid-column: span 2;
    }
}
@media (min-width: 900px) {
    .footer-panel-3 {
        grid-column: span 1;
    }
    .footer-top {
        grid-template-columns: 1fr 1fr 1fr;
    }
}