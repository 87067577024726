.articles-container {
    padding-top: var(--sm-spacing);
    display: grid;
    grid-template-columns: 1fr;
    gap: .5rem;
}
@media (min-width: 600px) {
    .articles-container {
        gap: 1rem;
    }
}
@media (min-width: 900px) {
    .articles-container {
        grid-template-columns: 25% 1fr;
    }
}
@media (min-width: 1536px) {
    .articles-container {
        grid-template-columns: 25% 1fr 25%;
    }
}