.mobile-menu {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    max-height: 100vh;
    background-color: var(--clr-bg);
    display: grid;
    grid-template-columns: 1fr;
    padding: calc(var(--y-spacing) * 3) var(--sm-spacing);
    transition: opacity 0.5s cubic-bezier(0.77,0.2,0.05,1.0) .1s, z-index 0.5s cubic-bezier(0.77,0.2,0.05,1.0) .1s, clip-path 0.5s ease-in .1s;
}
.mobile-menu.--inactive {
    z-index: -3;
    opacity: 0;
    clip-path: circle(4.1% at 100% 0);
}
.mobile-menu.--active {
    z-index: 3;
    opacity: 1;
    clip-path: circle(142.3% at 100% 0);
}
.mobile-menu .mobile-nav-item-container:not(:last-child) {
    margin-bottom: .67rem;
}
.mobile-menu .mobile-nav-item-container button {
    text-align: right;
}
.mobile-nav-item-container {
    position: relative;
    transform: translateX(calc(100% + var(--sm-spacing)));
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0) .1s;
}
.mobile-menu.--active .mobile-nav-item-container {
    transform: translateX(0);
}
.mobile-menu.--active .mobile-nav-item-container:nth-child(2) {
    transition-delay: 0.2s;
}
.mobile-menu.--active .mobile-nav-item-container:nth-child(3) {
    transition-delay: 0.3s;
}

/* Global */
.App {
    position: relative;
    overflow: hidden;
}
.App.--menu-active {
    max-height: 100vh;
}