.App-header {
    padding: var(--sm-spacing) var(--sm-spacing) 0 var(--sm-spacing);
}
.App-logo a,
.App-logo a:hover {
    color: var(--clr-light);
}
.App-logo svg:nth-child(2) {
    padding-left: 1.5rem;
}
.App-header nav.main-nav {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--sm-spacing);
    align-items: center;
    position: relative;
    z-index: 4;
}
@media (min-width: 600px) {
    .App-header nav.main-nav {
        grid-template-columns: min-content 1fr;
    }
}