.article-navigation-container {
    position: relative;
}
.article-navigation-container ul {
    list-style-type: none;
}
.article-navigation-container li .main {
    font-size: 1rem;
}
.article-navigation-container li .main strong {
    font-weight: 400;
    font-size: .9em;
    hyphens: auto;
}
.--dark-theme .article-navigation-container li .sub {
    font-size: .67rem;
    font-weight: 200;
}
.--art-theme .article-navigation-container li .sub {
    font-size: .67rem;
    color: var(--clr-primary-200);
}
.article-navigation-container li.clickable {
    cursor: pointer;
    padding: .67rem 1rem;
    background: transparent;
    transition: background-color 150ms ease-in;
    border-left: 3px solid transparent;
    border-top: 1px solid var(--clr-fade);
}
.article-navigation-container li.clickable:hover {
    background-color: var(--clr-fade);
}
.article-navigation-container li.--selected {
    border-left-color: var(--clr-primary-600);
    color: var(--clr-text);
}

/*tabs*/
@media (max-width: 900px) {
    .xsm-tabs {
        position: relative;
        max-height: 80vh;
        overflow-y: auto;
        background-color: var(--clr-bg);
        z-index: 2;
    }
    .xsm-tabs.--active {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border: 1px solid var(--clr-primary-600);
    }
    .article-navigation-container {
        min-height: 3rem;
        margin-bottom: 1rem;
        overflow: visible;
    }
    .visible-nav-item > span {
        display: inline-block;
    }
    .visible-nav-item > span:first-child {
        text-overflow: ellipsis;
        overflow-x: hidden;
        width: max(5rem, min(50vw, 25rem));
        white-space: nowrap;
    }
    .article-navigation-container li.--selected {
        background-color: var(--clr-fade);
        border: none;
    }
}