.landing-card-container {
    --col-x: minmax(10rem, 28rem);
    display: grid;
    grid-template-columns: var(--col-x);
    justify-content: center;
    gap: var(--sm-spacing);
}

@media (min-width: 600px) {
    .landing-card-container {
        grid-template-columns: repeat(2, var(--col-x));
    }
}
@media (min-width: 1536px) {
    .landing-card-container {
        grid-template-columns: repeat(3, var(--col-x));
    }
}