.card-container {
    --max-vid-y: 288px;
    position: relative;
}
.card-body {
    height: 100%;
}
.card-main {
    flex: 0 1 100%;
    padding: 1rem;
    border-left: 1px solid var(--clr-fade);
    border-right: 1px solid var(--clr-fade);
    border-bottom: 1px solid var(--clr-fade);
    background-color: var(--clr-bg);
}
.card-header h3 {
    font-size: .67em;
    font-weight: 400;
    letter-spacing: .05em;
}
.card-main p:not(.subtitle),
.card-main li {
    font-weight: 400;
    font-size: 1rem;
}
.card-footer {
    margin-top: 3rem;
}
.card-footer a {
    font-size: .67em;
    font-weight: 400;
    letter-spacing: .1em;
    color: var(--clr-secondary-0);
}